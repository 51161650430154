const promoConfig = {
	enabled: true,
	interval: 1000 * 30,
	banners: [
		{
			id: 7,
			url: '//www.lider-bet.com/ka/promotions/volt-2x-race',
			animated: true,
		},
	],
}

export default promoConfig
